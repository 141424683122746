<template>
  <div>
<!--头部-->
    <div style="display: flex;justify-content: space-between">
<!--选择工点查看-->
      <div>
        <el-select style="margin-left: 5px;margin-bottom: 20px;width: 200px" v-model="opername" placeholder="请选择洞口"
                   size="small" @change="getflag">
          <el-option
              v-for="item in opernames"
              :key="item.flag"
              :label="item.operatingpoint"
              :value="item.flag"
          >
          </el-option>
        </el-select>
      </div>
<!--      新增-->
      <div v-if="this.opername !== ''" style="margin-right: 200px">
        <el-button type="danger" size="mini" @click="addsink">新增观测点</el-button>
      </div>
    </div>
<!--表格-->
    <div>
      <vxe-table
          border
          resizable
          :align="allAlign"
          :data="tableData"
          size="mini"
          highlight-hover-row
          ref="xTable1"
          :export-config="{}"
      >
        <vxe-column field="mile" title="里程" width="200"></vxe-column>
        <vxe-column field="rock" title="围岩等级" width="200"></vxe-column>
        <vxe-column field="startime" title="预埋时间" width="115"></vxe-column>
        <vxe-column field="endtime" title="停测时间(观测日期)" width="120"></vxe-column>
        <vxe-column field="palnchange" title="设计预留变形量(mm)" width="150"></vxe-column>
        <vxe-column field="realchange" title="实际预留变形量(mm)" width="150"></vxe-column>
        <vxe-column field="realdown" title="实际下沉(mm)" width="120"></vxe-column>
        <vxe-column field="realsone" title="S1实际收敛(mm)" width="120"></vxe-column>
        <vxe-column field="realtwo" title="S2实际收敛(mm)" width="120"></vxe-column>
        <vxe-column field="beizhu" title="备注" width="110"></vxe-column>
        <vxe-column title="操作" width="100" show-overflow>
          <template #default="{ row }">
            <vxe-button size="mini" status="primary" @click="editsink(row)">修改</vxe-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
<!--    新增-->
    <div>
      <el-dialog
          v-model="dialogAdd"
          title="新增沉降检测点"
          width="80%"
          :destroy-on-close="true">
        <div>
          <el-tag style="margin-bottom: 10px;margin-left: 5px" type="danger">请选择预埋里程</el-tag>
          <el-select style="margin-left: 10px;" v-model="mile" placeholder="请选择里程" size="small" @change="getrocks">
            <el-option
                v-for="item in miles"
                :key="item.id"
                :label="item.mileage"
                :value="item.mileage"
            >
            </el-option>
          </el-select>
          <el-input style="margin-left: 5px;width: 330px" v-model="milexx" placeholder="请输入字母K后的完整里程 XXXXX" clearable size="small" @blur="getnum"/>
        </div>
        <div>
          <el-tag style="margin-bottom: 10px;margin-left: 5px" type="danger">请选择围岩等级</el-tag>
          <el-select style="margin-left: 5px;" v-model="form.rock" placeholder="请选择围岩等级" size="small">
            <el-option
                v-for="item in rocks"
                :key="item.level"
                :label="item.level"
                :value="item.level"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <el-tag style="margin-bottom: 10px;margin-left: 5px;margin-right: 10px" type="danger">请选择预埋时间</el-tag>
          <el-date-picker
              v-model="form.startime"
              type="date"
              size="small"
              placeholder="请选择预埋时间"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
          >
          </el-date-picker>
        </div>

        <div>
          <el-tag style="margin-bottom: 10px;margin-left: 5px" type="danger">设计预留变形量</el-tag>
          <el-input style="margin-left: 10px;width: 330px" v-model="form.palnchange" placeholder="请输入设计预留变形量(mm)" clearable size="small"/>
        </div>
        <div>
          <el-tag style="margin-bottom: 10px;margin-left: 5px" type="danger">实际预留变形量</el-tag>
          <el-input style="margin-left: 10px;width: 330px" v-model="form.realchange" placeholder="请输入实际预留变形量(mm)" clearable size="small"/>
        </div>
        <div>
          <el-tag style="margin-bottom: 10px;margin-left: 5px" type="danger">实&#12288;际&#12288;下&#12288;沉</el-tag>
          <el-input style="margin-left: 10px;width: 330px" v-model="form.realdown" placeholder="请输入实际下沉(mm)" clearable size="small"/>
        </div>
        <div>
          <el-tag style="margin-bottom: 10px;margin-left: 5px" type="danger">&#12288;S1实际收敛&#12288;</el-tag>
          <el-input style="margin-left: 10px;width: 330px" v-model="form.realsone" placeholder="请输入S1实际收敛(mm)" clearable size="small"/>
        </div>
        <div>
          <el-tag style="margin-bottom: 10px;margin-left: 5px" type="danger">&#12288;S2实际收敛&#12288;</el-tag>
          <el-input style="margin-left: 10px;width: 330px" v-model="form.realtwo" placeholder="请输入S2实际收敛(mm)" clearable size="small"/>
        </div>
        <div>
          <el-tag style="margin-bottom: 10px;margin-left: 5px" type="danger">&#12288;&#12288;备&#12288;注&#12288;&#12288;</el-tag>
          <el-input style="margin-left: 10px;width: 330px" v-model="form.beizhu" placeholder="请输入备注" clearable size="small"/>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="qvaddsink">取消</el-button>
            <el-button type="primary" @click="upsink">提交</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!--    修改-->
    <div>
      <el-dialog
          v-model="dialogUpdata"
          title="修改沉降检测点"
          width="80%"
          :destroy-on-close="true">
        <div>
          <el-tag style="margin-bottom: 10px;margin-left: 5px" type="danger">请选择预埋里程</el-tag>
          <el-input style="margin-left: 5px;width: 330px" v-model="form1.mile" disabled size="small"/>
        </div>
        <div>
          <el-tag style="margin-bottom: 10px;margin-left: 5px" type="danger">请选择围岩等级</el-tag>
          <el-select style="margin-left: 5px;" v-model="form1.rock" placeholder="请选择围岩等级" size="small" @focus="getrocks">
            <el-option
                v-for="item in rocks"
                :key="item.level"
                :label="item.level"
                :value="item.level"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <el-tag style="margin-bottom: 10px;margin-left: 5px;" type="danger">请选择预埋时间</el-tag>
          <el-input style="margin-left: 10px;width: 330px" v-model="form1.startime" disabled size="small"/>
        </div>
        <div>
          <el-tag style="margin-bottom: 10px;margin-left: 5px;margin-right: 10px" type="danger">请选择停测时间</el-tag>
          <el-date-picker
              v-model="form1.endtime"
              type="date"
              size="small"
              placeholder="请选择停测时间"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
          >
          </el-date-picker>
        </div>

        <div>
          <el-tag style="margin-bottom: 10px;margin-left: 5px" type="danger">设计预留变形量</el-tag>
          <el-input style="margin-left: 10px;width: 330px" v-model="form1.palnchange" disabled size="small"/>
        </div>
        <div>
          <el-tag style="margin-bottom: 10px;margin-left: 5px" type="danger">实际预留变形量</el-tag>
          <el-input style="margin-left: 10px;width: 330px" v-model="form1.realchange" disabled size="small"/>
        </div>
        <div>
          <el-tag style="margin-bottom: 10px;margin-left: 5px" type="danger">实&#12288;际&#12288;下&#12288;沉</el-tag>
          <el-input style="margin-left: 10px;width: 330px" v-model="form1.realdown" placeholder="请输入实际下沉(mm)" clearable size="small"/>
        </div>
        <div>
          <el-tag style="margin-bottom: 10px;margin-left: 5px" type="danger">&#12288;S1实际收敛&#12288;</el-tag>
          <el-input style="margin-left: 10px;width: 330px" v-model="form1.realsone" placeholder="请输入S1实际收敛(mm)" clearable size="small"/>
        </div>
        <div>
          <el-tag style="margin-bottom: 10px;margin-left: 5px" type="danger">&#12288;S2实际收敛&#12288;</el-tag>
          <el-input style="margin-left: 10px;width: 330px" v-model="form1.realtwo" placeholder="请输入S2实际收敛(mm)" clearable size="small"/>
        </div>
        <div>
          <el-tag style="margin-bottom: 10px;margin-left: 5px" type="danger">&#12288;&#12288;备&#12288;注&#12288;&#12288;</el-tag>
          <el-input style="margin-left: 10px;width: 330px" v-model="form1.beizhu" placeholder="请输入备注" clearable size="small"/>
        </div>
        <div style="display: flex">
          <el-tag style="margin-bottom: 10px;margin-left: 5px" type="danger">是&#12288;否&#12288;停&#12288;测</el-tag>
          <el-radio-group style="margin-left: 10px" v-model="form1.type">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogUpdata = false">取消</el-button>
            <el-button type="primary" @click="updatasink">修改</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ElMessage, ElMessageBox} from 'element-plus'
import {setNowDate} from "@/utils/time";
export default {
  inject: ['reload'],
  name: "sink",
  data(){
    return{
      opernames: [],
      opername: '',
      allAlign: 'center',
      tableData: [],
      dialogAdd: false,
      dialogUpdata: false,
      miles: [],
      mile: '',
      milexx: '',
      operid: '',
      rocks: [],
      form: {
        opername: '',
        mile: '',
        startime: '',
        endtime: '',
        palnchange: '',
        realchange: '',
        realdown: '',
        realsone: '',
        realtwo: '',
        opernameid: '',
        opername1: '',
        beizhu: '',
        rock: ''
      },
      form1: {
        id: '',
        opername: '',
        mile: '',
        startime: '',
        endtime: '',
        palnchange: '',
        realchange: '',
        realdown: '',
        realsone: '',
        realtwo: '',
        opernameid: '',
        opername1: '',
        beizhu: '',
        type: '0',
        rock: ''
      }
    }
  },
  created() {
    if (sessionStorage.getItem('operid')){
      this.opername = sessionStorage.getItem('opername');
      this.operid = sessionStorage.getItem('operid');
    }
    this.axios.post('/sink/getoper', (response) => {
      response.obj.forEach((item, i ) =>{
        item.forEach((item1, y) => {
          this.opernames.push(item1)
        })
      });
    },{
      bdst: this.GET_BDST
    })
  },
  computed: {
    ...mapGetters(['GET_BDST']),
    ...mapGetters(['GET_USER']),
    ...mapGetters(['GET_USERID']),
  },
  methods:{
    getrocks(){
      this.axios.get('/other/getallrock', (response) =>  {
        this.rocks = response.obj
      })
    },
    getsink(){
      if (this.opername === ''){
        ElMessage({
          message: '请选择隧道',
          type: 'warning',
        })
      }else {
        this.axios.post("/sink/getsinkz", (response) => {
          this.tableData = response.obj;
          console.log(this.tableData)
        },{
          operid: this.operid
        })
      }
    },
    getflag(val){
      this.operid = val;
      this.axios.post('/sink/getopername', (response) =>{
        this.opername1 = response.obj.operatingpoint;
        this.getsink();
      },{
        operid: val
      })
    },
    addsink(){
      if (this.opername === ''){
        ElMessage({
          message: '请选择隧道',
          type: 'warning',
        })
      }else {
        this.dialogAdd = true;
        this.axios.post('/other/getmil', (response) =>  {
          this.miles = response.obj
        },{
          id: this.operid
        })
      }
    },
    qvaddsink(){
      this.dialogAdd = false;
      this.mile = '';
      this.milexx = '';
      this.form = [];
    },
    getnum(){
      let d1 = this.milexx.split(".");
      if (d1[0].length < 4){
        ElMessageBox.alert('里程填写有误', '错误', {
          confirmButtonText: 'OK',
          callback: (action) => {
          },
        })
      }
    },
    upsink(){
      if (this.milexx === '' || this.form.startime === '' || this.form.palnchange === ''
          || this.form.realchange === '' || this.form.realdown === '' || this.form.realsone === ''
          || this.form.realtwo === ''){
        ElMessage.error('信息未填写完整')
      }else {
        this.form.mile = this.mile + this.milexx;
        this.form.opername = this.opername1;
        this.form.opernameid = this.operid;
        this.form.endtime = setNowDate(new Date());
        this.axios.post('/sink/savesink', (response) => {
          console.log(response)
          if (response.obj){
            this.dialogAdd = false;
            this.mile = '';
            this.milexx = '';
            this.form = [];
            this.axios.post("/sink/getsinkz", (response) => {
              this.tableData = response.obj;
              console.log(this.tableData)
            },{
              operid: this.operid
            })
          }
        },this.form)
      }
    },
    editsink(row){
      this.dialogUpdata = true;
      this.form1 = row
    },
    updatasink(){
      //this.form1.endtime = setNowDate(new Date());
      if (this.form1.beizhu === ''){
        this.form1.beizhu = ' ';
      }
      this.axios.post("/sink/upsink", (response) => {
        if (response.obj){
          ElMessage({
            message: '修改成功',
            type: 'success',
          });
          this.dialogUpdata = false;
          this.mile = '';
          this.milexx = '';
          this.form = [];
          this.axios.post("/sink/getsinkz", (response) => {
            this.tableData = response.obj;
            console.log(this.tableData)
          },{
            operid: this.operid
          })
        }
      }, this.form1)
    }
  }
}
</script>

<style scoped>

</style>