<template>
  <div>
    <div style="margin-left: 30px;padding-right: 100px">
      <el-form ref="form" :inline="true" :model="form" label-width="120px">
        <el-form-item>
          <el-tag style="margin-left: 5px" type="info">标段</el-tag>
          <el-select style="margin-left: 5px;margin-bottom: 20px;width: 150px" v-model="form.oper" placeholder="请选择标段"
                     @change="getoperid" size="small">
            <el-option
                v-for="item in bdsts"
                :key="item.bdst"
                :label="item.bdstname"
                :value="item.bdst"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-tag type="info">隧道名称</el-tag>
          <el-select style="margin-left: 5px" v-model="form.operption" placeholder="请选择隧道" size="small"
                     @change="operid">
            <el-option
                v-for="item in operptions"
                :key="item.operatingpoint"
                :label="item.operatingpoint"
                :value="item.flag"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-tag style="margin-bottom: 10px;margin-left: 5px" type="info">请选择围岩等级</el-tag>
          <el-select style="margin-left: 5px;width: 200px" v-model="form.rock" placeholder="请选择围岩等级" size="small"
                     @change="getpros">
            <el-option
                v-for="item in rocks"
                :key="item.level"
                :label="item.level"
                :value="item.level"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-tag style="margin-bottom: 10px;margin-left: 5px" type="info">请选择断面</el-tag>
          <el-select style="margin-left: 5px;" v-model="form.pro" placeholder="请选择断面" size="small" @change="getcuts">
            <el-option
                v-for="item in pros"
                :key="item.profile"
                :label="item.profile"
                :value="item.profile"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form-item>
        <el-tag style="margin-bottom: 10px;margin-left: 5px;margin-right: 10px" type="info">是否立架</el-tag>
        <el-radio v-model="radio1" label="2">是</el-radio>
        <el-radio v-model="radio1" label="1.5">否</el-radio>
      </el-form-item>
      <el-form-item>
        <el-tag style="margin-bottom: 10px;margin-left: 5px;margin-right: 10px" type="info">是否喷混凝土</el-tag>
        <el-radio @change="changebz" v-model="radio2" label="1">是</el-radio>
        <el-radio @change="changebz" v-model="radio2" label="0">否</el-radio>
      </el-form-item>
      <br>
      <div v-if="showbz1" style="margin-top: 20px;display: flex">
        <el-tag type="danger" style="margin-right: 10px">备注</el-tag>
        <el-input size="small" v-model="form.beizhu" placeholder="请说明未喷射混凝土的原因"/>
      </div>
      <br>
      <div v-if="xx">
        <div v-if="form.pro === '全断面'">
          <el-divider>{{ form.pro }}</el-divider>
          <el-form-item>
            <el-tag style="margin-bottom: 10px;margin-left: 5px" type="info">请选择施工里程</el-tag>
            <el-select style="margin-left: 5px;width: 150px" v-model="daunmian.mile" placeholder="请选择里程" size="small">
              <el-option
                  v-for="item in mils"
                  :key="item.id"
                  :label="item.mileage"
                  :value="item.mileage"
              >
              </el-option>
            </el-select>
            <el-input style="margin-left: 5px;width: 330px;margin-right: 50px" v-model="daunmian.milexx"
                      placeholder="请输入字母K后的完整里程 XXXXX-XXXXX"
                      clearable size="small" @blur="getnum(daunmian.mile, daunmian.milexx)"/>
          </el-form-item>
          <div style="display: flex;justify-content: space-between">
            <div>
              <el-tag type="info">设计量</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入设计量" v-model="daunmian.sj" @focus="getmileopp(daunmian.mile, daunmian.milexx)"/>
            </div>
            <div>
              <el-tag type="info">实际量</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入实际量" v-model="daunmian.shij"
                        @blur="getbeizhu(daunmian.sj, daunmian.shij)"/>
            </div>
            <div>
              <el-tag type="info">平均线性超挖</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入平均线性超挖" v-model="daunmian.pjcw"/>
            </div>
            <div>
              <el-tag type="info">最大线性超挖</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入设计量最大线性超挖" v-model="daunmian.zdcw"/>
            </div>
          </div>

          <el-divider style="margin-top: 50px">仰供支护</el-divider>
          <el-form-item>
            <el-tag style="margin-bottom: 10px;margin-left: 5px" type="info">请选择施工里程</el-tag>
            <el-select style="margin-left: 5px;width: 150px" v-model="daunmian.mile4" placeholder="请选择里程" size="small">
              <el-option
                  v-for="item in mils"
                  :key="item.id"
                  :label="item.mileage"
                  :value="item.mileage"
              >
              </el-option>
            </el-select>
            <el-input style="margin-left: 5px;width: 330px;margin-right: 50px" v-model="daunmian.milexx4"
                      placeholder="请输入字母K后的完整里程 XXXXX-XXXXX"
                      clearable size="small" @blur="getnum(daunmian.mile4, daunmian.milexx4)"/>
          </el-form-item>
          <div style="display: flex;justify-content: space-between">
            <div>
              <el-tag type="info">设计量</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入设计量" v-model="daunmian.sj4" @focus="getmileopp(daunmian.mile4, daunmian.milexx4)"/>
            </div>
            <div>
              <el-tag type="info">实际量</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入实际量" v-model="daunmian.shij4"
                        @blur="getbeizhu(daunmian.sj4, daunmian.shij4)"/>
            </div>
            <div>
              <el-tag type="info">平均线性超挖</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入平均线性超挖" v-model="daunmian.pjcw4"/>
            </div>
            <div>
              <el-tag type="info">最大线性超挖</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入设计量最大线性超挖" v-model="daunmian.zdcw4"/>
            </div>
          </div>

          <div v-if="showbz" style="margin-top: 20px;display: flex">
            <el-tag type="danger" style="margin-right: 10px">备注</el-tag>
            <el-input size="small" v-model="form.beizhu" placeholder="请说明实际用量过多的原因"/>
          </div>
        </div>
        <div v-if="form.pro === '微台阶法'">
          <el-divider>上台阶</el-divider>
          <el-form-item>
            <el-tag style="margin-bottom: 10px;margin-left: 5px" type="info">请选择施工里程</el-tag>
            <el-select style="margin-left: 5px;width: 150px" v-model="tj.mile1" placeholder="请选择里程" size="small">
              <el-option
                  v-for="item in mils"
                  :key="item.id"
                  :label="item.mileage"
                  :value="item.mileage"
              >
              </el-option>
            </el-select>
            <el-input style="margin-left: 5px;width: 330px;margin-right: 50px" v-model="tj.milexx1"
                      placeholder="请输入字母K后的完整里程 XXXXX-XXXXX"
                      clearable size="small" @blur="getnum(tj.mile1, tj.milexx1)"/>
          </el-form-item>
          <div style="display: flex;justify-content: space-between">
            <div>
              <el-tag type="info">设计量</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入设计量" v-model="tj.sj1" @focus="getmileopp(tj.mile1, tj.milexx1)"/>
            </div>
            <div>
              <el-tag type="info">实际量</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入实际量" v-model="tj.shij1"
                        @blur="getbeizhu(tj.sj1, tj.shij1)"/>
            </div>
            <div>
              <el-tag type="info">平均线性超挖</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入平均线性超挖" v-model="tj.pjcw1"/>
            </div>
            <div>
              <el-tag type="info">最大线性超挖</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入设计量最大线性超挖" v-model="tj.zdcw1"/>
            </div>
          </div>
          <el-divider style="margin-top: 50px">下台阶</el-divider>
          <el-form-item>
            <el-tag style="margin-bottom: 10px;margin-left: 5px" type="info">请选择施工里程</el-tag>
            <el-select style="margin-left: 5px;width: 150px" v-model="tj.mile3" placeholder="请选择里程" size="small">
              <el-option
                  v-for="item in mils"
                  :key="item.id"
                  :label="item.mileage"
                  :value="item.mileage"
              >
              </el-option>
            </el-select>
            <el-input style="margin-left: 5px;width: 330px;margin-right: 50px" v-model="tj.milexx3"
                      placeholder="请输入字母K后的完整里程 XXXXX-XXXXX"
                      clearable size="small" @blur="getnum(tj.mile3, tj.milexx3)"/>
          </el-form-item>
          <div style="display: flex;justify-content: space-between">
            <div>
              <el-tag type="info">设计量</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入设计量" v-model="tj.sj3" @focus="getmileopp(tj.mile3, tj.milexx3)"/>
            </div>
            <div>
              <el-tag type="info">实际量</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入实际量" v-model="tj.shij3"
                        @blur="getbeizhu(tj.sj3, tj.shij3)"/>
            </div>
            <div>
              <el-tag type="info">平均线性超挖</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入平均线性超挖" v-model="tj.pjcw3"/>
            </div>
            <div>
              <el-tag type="info">最大线性超挖</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入设计量最大线性超挖" v-model="tj.zdcw3"/>
            </div>
          </div>
          <el-divider style="margin-top: 50px">仰供支护</el-divider>
          <el-form-item>
            <el-tag style="margin-bottom: 10px;margin-left: 5px" type="info">请选择施工里程</el-tag>
            <el-select style="margin-left: 5px;width: 150px" v-model="tj.mile4" placeholder="请选择里程" size="small">
              <el-option
                  v-for="item in mils"
                  :key="item.id"
                  :label="item.mileage"
                  :value="item.mileage"
              >
              </el-option>
            </el-select>
            <el-input style="margin-left: 5px;width: 330px;margin-right: 50px" v-model="tj.milexx4"
                      placeholder="请输入字母K后的完整里程 XXXXX-XXXXX"
                      clearable size="small" @blur="getnum(tj.mile4, tj.milexx4)"/>
          </el-form-item>
          <div style="display: flex;justify-content: space-between">
            <div>
              <el-tag type="info">设计量</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入设计量" v-model="tj.sj4" @focus="getmileopp(tj.mile4, tj.milexx4)"/>
            </div>
            <div>
              <el-tag type="info">实际量</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入实际量" v-model="tj.shij4"
                        @blur="getbeizhu(tj.sj4, tj.shij4)"/>
            </div>
            <div>
              <el-tag type="info">平均线性超挖</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入平均线性超挖" v-model="tj.pjcw4"/>
            </div>
            <div>
              <el-tag type="info">最大线性超挖</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入设计量最大线性超挖" v-model="tj.zdcw4"/>
            </div>
          </div>
          <div v-if="showbz" style="margin-top: 20px;display: flex">
            <el-tag type="danger" style="margin-right: 10px">备注</el-tag>
            <el-input size="small" v-model="form.beizhu" placeholder="请说明实际用量过多的原因"/>
          </div>
        </div>
        <div v-if="form.pro === '台阶法'">
          <el-divider>上台阶</el-divider>
          <el-form-item>
            <el-tag style="margin-bottom: 10px;margin-left: 5px" type="info">请选择施工里程</el-tag>
            <el-select style="margin-left: 5px;width: 150px" v-model="tj.mile1" placeholder="请选择里程" size="small">
              <el-option
                  v-for="item in mils"
                  :key="item.id"
                  :label="item.mileage"
                  :value="item.mileage"
              >
              </el-option>
            </el-select>
            <el-input style="margin-left: 5px;width: 330px;margin-right: 50px" v-model="tj.milexx1"
                      placeholder="请输入字母K后的完整里程 XXXXX-XXXXX"
                      clearable size="small" @blur="getnum(tj.mile1, tj.milexx1)"/>
          </el-form-item>
          <div style="display: flex;justify-content: space-between">
            <div>
              <el-tag type="info">设计量</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入设计量" v-model="tj.sj1" @focus="getmileopp(tj.mile1, tj.milexx1)"/>
            </div>
            <div>
              <el-tag type="info">实际量</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入实际量" v-model="tj.shij1"
                        @blur="getbeizhu(tj.sj1, tj.shij1)"/>
            </div>
            <div>
              <el-tag type="info">平均线性超挖</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入平均线性超挖" v-model="tj.pjcw1"/>
            </div>
            <div>
              <el-tag type="info">最大线性超挖</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入设计量最大线性超挖" v-model="tj.zdcw1"/>
            </div>
          </div>
          <el-divider style="margin-top: 50px">中台阶</el-divider>
          <el-form-item>
            <el-tag style="margin-bottom: 10px;margin-left: 5px" type="info">请选择施工里程</el-tag>
            <el-select style="margin-left: 5px;width: 150px" v-model="tj.mile2" placeholder="请选择里程" size="small">
              <el-option
                  v-for="item in mils"
                  :key="item.id"
                  :label="item.mileage"
                  :value="item.mileage"
              >
              </el-option>
            </el-select>
            <el-input style="margin-left: 5px;width: 330px;margin-right: 50px" v-model="tj.milexx2"
                      placeholder="请输入字母K后的完整里程 XXXXX-XXXXX"
                      clearable size="small" @blur="getnum(tj.mile2, tj.milexx2)"/>
          </el-form-item>
          <div style="display: flex;justify-content: space-between">
            <div>
              <el-tag type="info">设计量</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入设计量" v-model="tj.sj2" @focus="getmileopp(tj.mile2, tj.milexx2)"/>
            </div>
            <div>
              <el-tag type="info">实际量</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入实际量" v-model="tj.shij2"
                        @blur="getbeizhu(tj.sj2, tj.shij2)"/>
            </div>
            <div>
              <el-tag type="info">平均线性超挖</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入平均线性超挖" v-model="tj.pjcw2"/>
            </div>
            <div>
              <el-tag type="info">最大线性超挖</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入设计量最大线性超挖" v-model="tj.zdcw2"/>
            </div>
          </div>
          <el-divider style="margin-top: 50px">下台阶</el-divider>
          <el-form-item>
            <el-tag style="margin-bottom: 10px;margin-left: 5px" type="info">请选择施工里程</el-tag>
            <el-select style="margin-left: 5px;width: 150px" v-model="tj.mile3" placeholder="请选择里程" size="small">
              <el-option
                  v-for="item in mils"
                  :key="item.id"
                  :label="item.mileage"
                  :value="item.mileage"
              >
              </el-option>
            </el-select>
            <el-input style="margin-left: 5px;width: 330px;margin-right: 50px" v-model="tj.milexx3"
                      placeholder="请输入字母K后的完整里程 XXXXX-XXXXX"
                      clearable size="small" @blur="getnum(tj.mile3, tj.milexx3)"/>
          </el-form-item>
          <div style="display: flex;justify-content: space-between">
            <div>
              <el-tag type="info">设计量</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入设计量" v-model="tj.sj3" @focus="getmileopp(tj.mile3, tj.milexx3)"/>
            </div>
            <div>
              <el-tag type="info">实际量</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入实际量" v-model="tj.shij3"
                        @blur="getbeizhu(tj.sj3, tj.shij3)"/>
            </div>
            <div>
              <el-tag type="info">平均线性超挖</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入平均线性超挖" v-model="tj.pjcw3"/>
            </div>
            <div>
              <el-tag type="info">最大线性超挖</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入设计量最大线性超挖" v-model="tj.zdcw3"/>
            </div>
          </div>
          <el-divider style="margin-top: 50px">仰供支护</el-divider>
          <el-form-item>
            <el-tag style="margin-bottom: 10px;margin-left: 5px" type="info">请选择施工里程</el-tag>
            <el-select style="margin-left: 5px;width: 150px" v-model="tj.mile4" placeholder="请选择里程" size="small">
              <el-option
                  v-for="item in mils"
                  :key="item.id"
                  :label="item.mileage"
                  :value="item.mileage"
              >
              </el-option>
            </el-select>
            <el-input style="margin-left: 5px;width: 330px;margin-right: 50px" v-model="tj.milexx4"
                      placeholder="请输入字母K后的完整里程 XXXXX-XXXXX"
                      clearable size="small" @blur="getnum(tj.mile4, tj.milexx4)"/>
          </el-form-item>
          <div style="display: flex;justify-content: space-between">
            <div>
              <el-tag type="info">设计量</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入设计量" v-model="tj.sj4" @focus="getmileopp(tj.mile4, tj.milexx4)"/>
            </div>
            <div>
              <el-tag type="info">实际量</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入实际量" v-model="tj.shij4"
                        @blur="getbeizhu(tj.sj4, tj.shij4)"/>
            </div>
            <div>
              <el-tag type="info">平均线性超挖</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入平均线性超挖" v-model="tj.pjcw4"/>
            </div>
            <div>
              <el-tag type="info">最大线性超挖</el-tag>
              <el-input size="small" style="width: 200px" placeholder="请输入设计量最大线性超挖" v-model="tj.zdcw4"/>
            </div>
          </div>
          <div v-if="showbz" style="margin-top: 20px;display: flex">
            <el-tag type="danger" style="margin-right: 10px">备注</el-tag>
            <el-input size="small" v-model="form.beizhu" placeholder="请说明实际用量过多的原因"/>
          </div>
        </div>
      </div>
      <div style="float: right;margin-right: 30px;margin-top: 30px">
        <el-button type="primary" @click="upbeton">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {algorithm} from "@/utils/algorithm";
import {ElMessageBox, ElMessage } from "element-plus";

export default {
  inject: ['reload'],
  name: "firstshor",
  data() {
    return {
      bdsts: [],
      operptions: [],
      mils: [],
      pros: [],
      rocks: [],
      cuts: [],
      form: {
        oper: '',
        operption: '',
        mil: '',
        milxx: '',
        pro: '',
        rock: '',
        proname: '',
        milsum: '',
        beizhu: '',
        lastshow: ''
      },
      xx: false,
      daunmian: {
        sj: '',
        shij: '',
        pjcw: '',
        zdcw: '',
        mile: '',
        milexx: '',
        oppmile: '',
        sj4: '',
        shij4: '',
        pjcw4: '',
        zdcw4: '',
        mile4: '',
        milexx4: '',
        oppmile4: ''
      },
      tj: {
        sj1: '',
        shij1: '',
        pjcw1: '',
        zdcw1: '',
        sj2: '',
        shij2: '',
        pjcw2: '',
        zdcw2: '',
        sj3: '',
        shij3: '',
        pjcw3: '',
        zdcw3: '',
        sj4: '',
        shij4: '',
        pjcw4: '',
        zdcw4: '',
        mile1: '',
        milexx1: '',
        oppmile1: '',
        mile2: '',
        milexx2: '',
        oppmile2: '',
        mile3: '',
        milexx3: '',
        oppmile3: '',
        mile4: '',
        milexx4: '',
        oppmile4: '',
      },
      showbz: false,
      showbz1: false,
      uplast: true,
      radio1: '2',
      radio2: '1'
    }
  },
  created() {
    this.axios.get('/other/exzx', (response) => {
      this.bdsts = response.obj
    });
  },
  methods: {
    getoperid(value) {
      this.bdst = value;
      this.axios.post('/other/allpro', (response) => {
        this.operptions = response.obj
      }, {
        bdst: value
      })
    },
    getpros() {
      this.axios.get('/other/getallpro', (response) => {
        this.pros = response.obj
      })
    },
    getcuts() {
      this.xx = true;
      console.log(this.form.pro)
    },
    operid(value) {
      this.form.lastshow = value;
      this.axios.get('/other/getallrock', (response) => {
        this.rocks = response.obj
      })
      this.axios.post('/other/getmil', (response) => {
        this.mils = response.obj
      }, {
        id: value
      })
      this.axios.post("/other/onepro", (response) => {
        this.form.proname = response.obj[0].operatingpoint;
      }, {
        id: this.form.operption
      })


    },
    upbeton() {
      if (this.showbz1){
        this.daunmian.shij = '0';
        this.daunmian.zdcw = '0';
        this.daunmian.pjcw = '0';
        this.daunmian.zdcw = '0';
        this.daunmian.mile = 'AK';
        this.daunmian.milexx = '0000-0000';
        this.daunmian.sj = '1'
        this.form.pro = '全断面';
      }
      let arr = [];
      if (this.form.pro === '全断面') {
        arr.push(this.daunmian);
      } else {
        arr.push(this.tj);
      }
      if (this.uplast){
        this.axios.post('/czbeton/savebeton', (response) => {
          if (response.obj){
            this.reload();
            ElMessage({
              message: '提交成功',
              type: 'success',
            });
          }else {
            ElMessage.error('提交失败，未按要求填写！');
          }

        }, {
          betonmain: JSON.stringify(this.form),
          betonchil: JSON.stringify(arr),
          consu: this.radio1
        })
      }else {
        ElMessageBox.alert('信息填写有误', '错误', {
          confirmButtonText: 'OK',
          callback: (action) => {
          },
        })
      }
    },
    getnum(val1, val2){
      let arr = val2.split("-");
      let d1 = arr[0].split(".");
      let d2 = arr[1].split(".");
      if (d1[0].length < 4 || d2[0].length < 4){
        ElMessageBox.alert('里程填写有误', '错误', {
          confirmButtonText: 'OK',
          callback: (action) => {
          },
        })
      }
    },
    getmileopp(val1, val2){
      if (val1 !== '' && val2 === ''){
        ElMessage.error('请填写具体里程');
        this.uplast = false
      }
    },
    getbeizhu(val1, val2) {
      //const l1 = val1 * 2;
      const l2 = parseFloat((val2 - val1).toFixed(2)); // val2实际 - val1设计  parseFloat((0.05 + 0.01).toFixed(2));
      //console.log(l2)
      if (!this.showbz){
        this.showbz = l2 / val1 >= 1.3
      }
    },
    changebz(val){
      this.showbz1 = !this.showbz1
    }
  }
}
</script>

<style scoped>

</style>