<template>
  <div>
    <div style="margin-left: 30px;padding-right: 100px">
      <el-form ref="form" :inline="true" :model="form" label-width="120px">
        <el-form-item>
          <el-tag type="info">标段</el-tag>
          <el-select style="margin-left: 5px;margin-bottom: 20px" v-model="form.oper" placeholder="请选择标段" @change="getoperid" size="small">
            <el-option
                v-for="item in form.bdsts"
                :key="item.bdst"
                :label="item.bdstname"
                :value="item.bdst"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-tag type="info">隧道名称</el-tag>
          <el-select style="margin-left: 5px" v-model="form.operption" placeholder="请选择隧道" size="small" @change="operid">
            <el-option
                v-for="item in form.operptions"
                :key="item.operatingpoint"
                :label="item.operatingpoint"
                :value="item.flag"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-tag style="margin-right: 5px" type="info">施工日期</el-tag>
          <el-date-picker
              v-model="form.sgtime"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="medium"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
              :clearable="false"
              unlink-panels
          >
          </el-date-picker>
        </el-form-item>
        <br/>
        <el-form-item>
          <el-radio-group v-model="milechange">
            <el-tag style="margin-right: 5px" type="info">是否进尺：</el-tag>
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item style="margin-left: 80px">
          <el-radio-group v-model="lj">
            <el-tag style="margin-right: 5px" type="info">是否立架：</el-tag>
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>

        <br>
        <div v-if="milechange === 0">
          <el-form-item>
            <el-tag type="info">情况说明</el-tag>
            <el-input size="small" v-model="beizhu" style="width: 350px" placeholder="填写未进尺原因" clearable />
          </el-form-item>
        </div>
        <div v-if="milechange === 1">
          <el-form-item>
            <el-tag style="margin-bottom: 10px;margin-left: 5px" type="info">请选择施工里程</el-tag>
            <el-select style="margin-left: 5px;" v-model="form.mil" placeholder="请选择里程" size="small">
              <el-option
                  v-for="item in form.mils"
                  :key="item.id"
                  :label="item.mileage"
                  :value="item.mileage"
              >
              </el-option>
            </el-select>
            <el-input style="margin-left: 5px;width: 330px" v-model="form.milxx" placeholder="请输入字母K后的完整里程 XXXXX-XXXXX" clearable size="small" @blur="getnum"/>
          </el-form-item>
          <el-form-item>
            <el-tag type="info">今日开挖/m</el-tag>
            <el-input style="margin-left: 5px;width: 100px" disabled v-model="form.milsum" placeholder="今日开挖" clearable size="small"/>
          </el-form-item>
          <br>
          <el-form-item>
            <el-tag style="margin-bottom: 10px;margin-left: 5px" type="info">请选择榀数</el-tag>
            <el-select style="margin-left: 5px;" v-model="form.ps" placeholder="请选择榀数" size="small" @change="getrocks">
              <el-option
                  v-for="item in form.pss"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-tag style="margin-bottom: 10px;margin-left: 5px" type="info">请选择围岩等级</el-tag>
            <el-select style="margin-left: 5px;" v-model="form.rock" placeholder="请选择围岩等级" size="small" @change="getpros">
              <el-option
                  v-for="item in form.rocks"
                  :key="item.level"
                  :label="item.level"
                  :value="item.level"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-tag style="margin-bottom: 10px;margin-left: 5px" type="info">请选择断面</el-tag>
            <el-select style="margin-left: 5px;" v-model="form.pro" placeholder="请选择断面" size="small" @change="getcuts">
              <el-option
                  v-for="item in form.pros"
                  :key="item.profile"
                  :label="item.profile"
                  :value="item.profile"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-divider></el-divider>
      </el-form>
    </div>
    <div v-if="timeshow" style="margin-left: 30px;padding-right: 100px">
      <div style="display: flex;margin-bottom: 20px" v-for="(item, i) in this.type">
        <el-tag v-if="item.timename.length === 2" type="danger" style="margin-right: 10px">&emsp;&emsp;{{ item.timename }}&emsp;&emsp;</el-tag>
        <el-tag v-else type="danger" style="margin-right: 10px">{{ item.timename }}</el-tag>
        <el-date-picker
            v-model="times[i]"
            type="datetimerange"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            size="small"
            style="margin-right: 80px"
            :clearable="false"
            @change="gettime(i)"
        >
        </el-date-picker>
        <el-tag style="margin-right: 10px;">情况说明</el-tag>
        <el-input size="small" v-model="spacetimes[i]" style="width: 250px;margin-left: 20px" placeholder="间隔时间过长原因(简要说明)" clearable />
        <el-input size="small" v-model="peos[i]" style="width: 250px;margin-left: 20px" placeholder="人员及设备投入情况" clearable />
        <el-input size="small" v-model="sms[i]" style="width: 350px;margin-left: 20px" placeholder="单工序施工时间异常原因、间隔时间过长详细说明" clearable />
      </div>
    </div>
    <div>
      <vxe-modal v-model="popwin" :title="this.form.proname" width="900" height="550" show-zoom resize remember>
        <template #default>
          <div>
            <el-tag size="small" type="danger">基本信息</el-tag>
          </div>
          <vxe-form :data="popwinmx" size="medium">
            <vxe-form-item title="施工日期" :item-render="{}">
              <template #default="{ data }">
                <vxe-input v-model="data.sgtime" disabled></vxe-input>
              </template>
            </vxe-form-item>
            <div>

            </div>
            <vxe-form-item title="今日施工里程" :item-render="{}">
              <template #default="{ data }">
                <vxe-input v-model="data.mile" disabled></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item title="今日进尺(m)" :item-render="{}">
              <template #default="{ data }">
                <vxe-input v-model="data.miletoday" disabled></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item title="榀数" :item-render="{}">
              <template #default="{ data }">
                <vxe-input v-model="data.ps" disabled></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item title="围岩等级" :item-render="{}">
              <template #default="{ data }">
                <vxe-input v-model="data.level" disabled></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item title="断面方式" :item-render="{}">
              <template #default="{ data }">
                <vxe-input v-model="data.cut" disabled></vxe-input>
              </template>
            </vxe-form-item>
          </vxe-form>
          <div v-if="milechange === 0" style="margin-top: 30px">
            <el-tag type="danger" size="mini" style="margin-bottom: 10px">未进尺原因：</el-tag>
            <el-input disabled size="mini" v-model="beizhu"/>
          </div>
          <div v-if="milechange === 1">
            <div style="margin-top: 20px">
              <el-tag size="small" type="danger">工序用时</el-tag>
            </div>
            <vxe-form :data="popwinmx" size="medium">
              <vxe-form-item title="开挖" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.kwtime" disabled></vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item title="情况说明" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.kwexcept" disabled></vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item title="人员及设备情况" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.kwpeo" disabled></vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item title="出渣" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.cztime" disabled></vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item title="情况说明" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.czexcept" disabled></vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item title="人员及设备情况" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.czpeo" disabled></vxe-input>
                </template>
              </vxe-form-item>

              <vxe-form-item title="立架(挂网)" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.ljtime" disabled></vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item title="情况说明" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.ljexcept" disabled></vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item title="人员及设备情况" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.ljpeo" disabled></vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item title="喷锚" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.pmtime" disabled></vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item title="情况说明" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.pmexcept" disabled></vxe-input>
                </template>
              </vxe-form-item>
              <vxe-form-item title="人员及设备情况" :item-render="{}">
                <template #default="{ data }">
                  <vxe-input v-model="data.pmpeo" disabled></vxe-input>
                </template>
              </vxe-form-item>
            </vxe-form>
          </div>
            <br/>
          <vxe-form>
            <vxe-form-item>
              <div v-for="(fit, i) in fileList" >
                  <el-avatar shape="square" :size="100" :src="fit.url"></el-avatar>
              </div>
            </vxe-form-item>
            <vxe-form-item align="center" span="24">
              <template #default>
                <vxe-button type="submit" status="primary" @click="updata">提交</vxe-button>
                <vxe-button type="reset" @click="popwin = false">返回</vxe-button>
              </template>
            </vxe-form-item>
          </vxe-form>
        </template>
      </vxe-modal>
    </div>
    <div style="margin-left: 30px">
      <el-tag style="margin-bottom: 10px" type="warning" size="small">上传图片</el-tag>
      <el-upload ref="uploadDemo" name="file"
                 action="#"
                 :http-request="upimg"
                 list-type="picture-card"
                 :auto-upload="false"
                 :on-change="imgchange"
                 :on-remove="handleRemove"
              >
        <template #default>
          <i class="el-icon-plus"></i>
        </template>
        <template #file="{ fileList }">
          <div v-for="file in fileList">
            <img :src="file.url" alt="" />
            <span>
              <span
                class="el-upload-list__item-delete">
                <i class="el-icon-delete" @click=""></i>
              </span>
            </span>
          </div>
        </template>
      </el-upload>
    </div>
    <div style="float: right;margin-right: 80px">
      <el-popconfirm
          confirm-button-text="已完成"
          cancel-button-text="未完成"
          icon="el-icon-info"
          icon-color="red"
          title="本次循环是否完成?(未施工也算未完成)"
          @confirm="confirmEvent"
          @cancel="cancelEvent"
      >
        <template #reference>
          <el-button type="primary">生成报表</el-button>
        </template>
      </el-popconfirm>
    </div>
    <div>
      <el-dialog v-model="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus';
import {setNowDate, setNowHou, setNowMonth, setNowTimes} from '@/utils/time';
import {algorithm} from "@/utils/algorithm";
import {mapGetters} from "vuex";
import Head from "@/views/header";
export default {
  components: {Head},
  inject: ['reload'],
  name: "sdgx",
  data(){
    return{
      allAlign: 'center',
      timeshow: false,
      beizhu: '',
      form: {
        bdsts: [],
        operptions:  [],
        mils: [],
        pss: [{
          value: 0,
          label: 0
        },{
          value: 1,
          label: 1
        },{
          value: 2,
          label: 2
        },{
          value: 3,
          label: 3
        },{
          value: 4,
          label: 4
        },{
          value: 5,
          label: 5
        },{
          value: 6,
          label: 6
        }],
        pros: [],
        rocks: [],
        cuts: [],
        oper: '',
        operption: '',
        sgtime: '',
        operptionid: '',
        mil: '',
        milxx: '',
        ps: '',
        pro: '',
        rock: '',
        cut: '',
        timestatus: '',
        proname: '',
        time:'',
        milsum: ''
      },
      type: [],
      flag1: true,
      times: [],
      sms: [],
      peos: [],
      spacetimes: [],
      uuid: '',
      starttime: [],
      endtime: [],
      lastshow: '',
      over: '',
      flag: true,
      popwin: false,
      popwinmx: {
        sxdate: '',
        mile: '',
        miletoday: '',
        ps: '',
        level: '',
        cut: '',
        kwtime: '',
        kwexcept: '',
        kwpeo: '',
        cztime: '',
        czexcept: '',
        czpeo: '',
        cptime: '',
        cpexcept: '',
        cppeo: '',
        ljtime: '',
        ljexcept: '',
        ljpeo: '',
        pmtime: '',
        pmexcept: '',
        pmpeo: '',
      },
      userid: '',
      disabled1: false,
      fileList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      bdst: '',
      milechange: 0,
      lj: 0,
      nojc: '',
      finshed: 0, // 默认完成
      spacetime: ''
    }
  },
  created() {
    this.axios.get('/other/exzx', (response) =>  {
      this.form.bdsts = response.obj
    });
    this.userid = this.GET_USERID;

  },
  computed: {
    ...mapGetters(['GET_USER']),
    ...mapGetters(['GET_USERID'])
  },
  methods:{
    confirmEvent() {
      this.finshed = 0;
      this.openpop();
    },
    cancelEvent() {
      this.finshed = 1;
      this.openpop();
    },
    upimg(){
      this.fileList.forEach((item, index) => {
        this.axios.post("https://www.cr12cz.cn/czscsms/up/upload", (response) => {
          console.log(response)
          if (response.obj){
            this.$refs.uploadDemo.clearFiles()
          }
        },{
          file: item.raw,
          userid: this.userid
        })
      })
    },
    imgchange(file){
      this.fileList.push(file);
    },
    handleRemove(file) {
      for (let i = 0; i < this.fileList.length; i++){
        if (this.fileList[i].raw.lastModified === file.raw.lastModified){
          this.fileList.splice(i, 1)
        }
      }
    },
    getoperid(value){
      this.bdst = value;
      this.axios.post('/other/allpro', (response) =>  {
        this.form.operptions = response.obj
      },{
        bdst: value
      })
    },
    operid(value){
      this.lastshow = value;
      this.axios.post('/other/getmil', (response) =>  {
        this.form.mils = response.obj
      },{
        id: value
      })
      this.axios.post("/other/onepro", (response) => {
        this.form.proname = response.obj[0].operatingpoint;
      },{
        id: this.form.operption
      })
      this.axios.post("/collectmain/getnew", (response) => {
        console.log(response)
      },{
        flag: this.lastshow
      })
    },
    getrocks(){
      this.axios.get('/other/getallrock', (response) =>  {
        this.form.rocks = response.obj
      })
    },
    getpros(){
      this.axios.get('/other/getallpro', (response) =>  {
        this.form.pros = response.obj
      })
    },
    getcuts(){
      this.axios.post("/other/onepro", (response) => {
        this.form.proname = response.obj[0].operatingpoint
        this.timeshow = true;
        this.axios.post("/other/getime", (response) => {
          this.type =  response.obj
        },{
          status: 0
        })
      },{
        id: this.form.operption
      });
      ElMessage({
        duration: '5000',
        message: '工序原因说明有调整，请注意按要求填写情况说明！',
        type: 'warning',
      })
    },
    gettime(i){
      this.starttime[i] = this.times[i][0];
      this.endtime[i] = this.times[i][1];
    },
    updata(){
      let now = new Date();
      let nowTime = now.getTime();
      let year = now.getFullYear();
      let month = now.getMonth() + 1;//js从0开始取
      let date = now.getDate();
      let deadlineStr = year + "/" + month + "/" + date + " " + "19:40:00";
      let deadline = Date.parse(deadlineStr);
      this.popwin = false;
      if (!this.starttime[0]){
        let month = setNowMonth();
        let date = setNowTimes(new Date());
        if (nowTime <= deadline){
          this.over = 0;
        }
        if (nowTime > deadline){
          this.over = 1;
        }
        let peonums = '';
        for (let i = 0 ; i < this.peos.length; i++){
          if (this.peos[i]){
            if (i === 0){
              peonums = peonums + this.peos[i]
            }else {
              peonums = peonums + ';' + this.peos[i]
            }
          }
        }
        for (let i = 0; i < this.sms.length; i++){
          if (this.sms[i]){
            if (this.beizhu === ''){
              this.beizhu = this.beizhu + this.sms[i]
            }else {
              this.beizhu = this.beizhu + ';' + this.sms[i]
            }
          }
        }

        for (let i = 0; i < this.spacetimes.length; i++){
          if (this.spacetimes[i]){
            if (this.spacetime === ''){
              this.spacetime = this.spacetime + this.spacetimes[i]
            }else {
              this.spacetime = this.spacetime + ';' + this.spacetimes[i]
            }
          }
        }

        this.axios.post("/collectmain/savecol", (response) => {
          this.uuid = response.message;
          this.axios.post("/other/adduserup", (response) => {
            if (response.obj){
              this.reload();
              ElMessage({
                message: '提交成功！',
                type: 'success',
              })
              this.fileList.forEach((item, index) => {
                this.axios.post("https://www.cr12cz.cn/czscsms/up/upload", (responseup) => {
                  if (responseup.obj){
                    this.axios.post("/other/saveimg", (responseimg) => {
                    },{
                      userid: this.GET_USERID,
                      formname: '隧道工效',
                      collectid: this.uuid,
                      imgurl: responseup.obj
                    })
                    this.$refs.uploadDemo.clearFiles();
                  }
                },{
                  file: item.raw,
                  userid: this.userid
                })
              })
            }
          },{
            username: this.GET_USER,
            proname: this.form.proname,
            time: setNowTimes(new Date()),
            formname: '隧道工效',
            overtime: this.over,
            flag: this.lastshow,
            userid: this.GET_USERID,
            collectid: this.uuid,
            back: 0
          })
        },{
          datatime: this.form.sgtime,
          mile: this.form.mil + this.form.milxx,
          pinshu: this.form.ps,
          level: this.form.rock,
          daunmian: this.form.pro,
          bdst: this.bdst,
          operpotion: this.form.proname,
          milesum: this.form.milsum,
          peonum: peonums,
          beizhu: this.beizhu,
          moth: month,
          adddate: date,
          flag: this.lastshow,
          th: this.lj,
          isend: this.finshed,
          spacetime: this.spacetime
        })
      }else {
        this.uptwo();
      }
    },
    uptwo(){
      let now = new Date();
      let nowTime = now.getTime();
      let year = now.getFullYear();
      let month1 = now.getMonth() + 1;//js从0开始取
      let date1 = now.getDate();
      let deadlineStr = year + "/" + month1 + "/" + date1 + " " + "19:40:00";
      let deadline = Date.parse(deadlineStr);
      this.popwin = false;
      let month = setNowMonth();
      let date = setNowTimes(new Date());
      if (nowTime <= deadline){
        this.over = 0;
      }
      if (nowTime > deadline){
        this.over = 1;
      }
      let peonums = '';
      for (let i = 0 ; i < this.peos.length; i++){
        if (this.peos[i]){
          if (i === 0){
            peonums = peonums + this.peos[i]
          }else {
            peonums = peonums + ';' + this.peos[i]
          }
        }
      }
      for (let i = 0; i < this.sms.length; i++){
        if (this.sms[i]){
          if (this.beizhu === ''){
            this.beizhu = this.beizhu + this.sms[i]
          }else {
            this.beizhu = this.beizhu + ';' + this.sms[i]
          }
        }
      }

      for (let i = 0; i < this.spacetimes.length; i++){
        if (this.spacetimes[i]){
          if (this.spacetime === ''){
            this.spacetime = this.spacetime + this.spacetimes[i]
          }else {
            this.spacetime = this.spacetime + ';' + this.spacetimes[i]
          }
        }
      }

      this.axios.post("/collectmain/savecol", (response) => {
        this.uuid = response.message;
        if (this.uuid){
          this.fileList.forEach((item, index) => {
            this.axios.post("https://www.cr12cz.cn/czscsms/up/upload", (responseup) => {
              if (responseup.obj){
                this.axios.post("/other/saveimg", (responseimg) => {
                },{
                  userid: this.GET_USERID,
                  formname: '隧道工效',
                  collectid: this.uuid,
                  imgurl: responseup.obj
                })
                this.$refs.uploadDemo.clearFiles();
              }
            },{
              file: item.raw,
              userid: this.userid
            })
          })
        }
        for (let  i = 0; i < this.type.length; i++){
          if (this.starttime[i]){
            this.axios.post("/colltime/savecolltim", (response) => {
              this.flag = response.obj
            },{
              timename: this.type[i].timename,
              starttime: setNowTimes(this.starttime[i]),
              collectid: this.uuid,
              endtime: setNowTimes(this.endtime[i]),
              flag: i,
              except: this.sms[i],
              peonum: this.peos[i]
            })
          }
          if (i === this.type.length - 1 && this.over !== ''){
            this.axios.post("/other/adduserup", (response) => {
              if (response.obj){
                this.reload();
                ElMessage({
                  message: '提交成功！',
                  type: 'success',
                })
              }
            },{
              username: this.GET_USER,
              proname: this.form.proname,
              time: setNowTimes(new Date()),
              formname: '隧道工效',
              overtime: this.over,
              flag: this.lastshow,
              userid: this.GET_USERID,
              collectid: this.uuid,
              back: 0
            })
          }
        }
      },{
        datatime: this.form.sgtime,
        mile: this.form.mil + this.form.milxx,
        pinshu: this.form.ps,
        level: this.form.rock,
        daunmian: this.form.pro,
        bdst: this.bdst,
        operpotion: this.form.proname,
        milesum: this.form.milsum,
        peonum: peonums,
        beizhu: this.beizhu,
        moth: month,
        adddate: date,
        flag: this.lastshow,
        th: this.lj,
        isend: this.finshed,
        spacetime: this.spacetime
      })
    },
    getnum(){
      if (this.form.milxx !== ''){
        let arr = this.form.milxx.split("-");
        if (arr.length === 1){
          this.form.milsum = '0.0'
        }else {
          if (parseInt(arr[0]) > parseInt(arr[1])){
            this.form.milsum = algorithm(arr[0], -arr[1])
          }else {
            this.form.milsum = algorithm(arr[1], -arr[0])
          }
          this.form.milsum = Math.abs(this.form.milsum);
          let d1 = arr[0].split(".");
          let d2 = arr[1].split(".");
          if (d1[0].length < 4 || d2[0].length < 4){
            ElMessageBox.alert('里程填写有误', '错误', {
              confirmButtonText: 'OK',
              callback: (action) => {

              },
            })
          }
        }
      }else {
        this.form.milsum = ''
      }
    },
    openpop(){
      this.popwin = true;
      var t = this.form.sgtime+'';
      let arr = t.split(",");
      if (arr[0] === arr[1]){
        this.popwinmx.sgtime = arr[0].slice(0,4) + "." + arr[0].slice(5,7) + "." + arr[0].slice(8,10);
      }else {
        this.popwinmx.sgtime = arr[0].slice(0,4) + "." + arr[0].slice(5,7) + "." + arr[0].slice(8,10) + '-' + arr[1].slice(5,7)
                               + '.' + arr[1].slice(8,10);
      }
      this.popwinmx.mile = this.form.mil + this.form.milxx;
      this.popwinmx.miletoday = this.form.milsum;
      this.popwinmx.ps = this.form.ps;
      this.popwinmx.level = this.form.rock;
      this.popwinmx.cut = this.form.pro;
      for (let  i = 0; i < this.type.length; i++) {
        if (this.starttime[i]){
          let start = this.starttime[i];
          let end = this.endtime[i]
          start = setNowTimes(this.starttime[i]);
          end = setNowTimes(this.endtime[i]);
          if (this.type[i].timename === '开挖'){
            this.popwinmx.kwtime = start.slice(11) + " - " +  end.slice(11);
            this.popwinmx.kwexcept = this.sms[i];
            this.popwinmx.kwpeo = this.peos[i];
          }
          if (this.type[i].timename === '出渣'){
            this.popwinmx.cztime = start.slice(11) + " - " +  end.slice(11);
            this.popwinmx.czexcept = this.sms[i];
            this.popwinmx.czpeo = this.peos[i];
          }
          if (this.type[i].timename === '初喷'){
            this.popwinmx.cptime = start.slice(11) + " - " +  end.slice(11);
            this.popwinmx.cpexcept = this.sms[i];
            this.popwinmx.cppeo = this.peos[i];
          }
          if (this.type[i].timename === '立架(挂网)'){
            this.popwinmx.ljtime = start.slice(11) + " - " +  end.slice(11);
            this.popwinmx.ljexcept = this.sms[i];
            this.popwinmx.ljpeo = this.peos[i];
          }
          if (this.type[i].timename === '喷锚'){
            this.popwinmx.pmtime = start.slice(11) + " - " +  end.slice(11);
            this.popwinmx.pmexcept = this.sms[i];
            this.popwinmx.pmpeo = this.peos[i];
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
