<template>
  <div style="margin-left: 10px">
    <el-divider style="margin-top: 10px">二衬报表</el-divider>
    <div style="display: flex;margin-top: 10px">
<!--      洞口-->
      <div style="margin-right: 60px">
        <el-tag type="info">隧道名称</el-tag>
        <el-select style="margin-left: 5px;margin-bottom: 20px;width: 200px" v-model="opername" placeholder="请选择洞口"
                   size="small" @change="getflag">
          <el-option
              v-for="item in opernames"
              :key="item.flag"
              :label="item.operatingpoint"
              :value="item.flag"
          >
          </el-option>
        </el-select>
      </div>

<!--      里程-->
      <div>
        <el-tag style="margin-bottom: 10px;margin-left: 5px" type="info">施工里程</el-tag>
        <el-select style="margin-left: 5px;width: 130px" v-model="mil" placeholder="请选择里程" size="small" @change="getrocks">
          <el-option
              v-for="item in mils"
              :key="item.id"
              :label="item.mileage"
              :value="item.mileage"
          >
          </el-option>
        </el-select>
        <el-input style="margin-left: 5px;width: 310px" v-model="milxx" placeholder="请输入字母K后的完整里程 XXXXX-XXXXX" clearable size="small" @blur="getnum"/>
      </div>
    </div>
    <div style="display: flex">
<!--      围岩等级-->
      <div style="margin-right: 60px">
        <el-tag style="margin-bottom: 10px;" type="info">围岩等级</el-tag>
        <el-select style="margin-left: 5px;width: 200px" v-model="rock" placeholder="请选择围岩等级" size="small">
          <el-option
              v-for="item in rocks"
              :key="item.level"
              :label="item.level"
              :value="item.level"
          >
          </el-option>
        </el-select>
      </div>
<!--      浇筑时间-->
      <div>
        <el-tag style="margin-bottom: 10px;margin-left: 5px;margin-right: 5px" type="info">浇筑时间</el-tag>
        <el-date-picker
            style="width: 200px"
            size="small"
            v-model="jztime"
            type="date"
            placeholder="选择浇筑时间"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
        >
        </el-date-picker>
      </div>
    </div>
<!--    数据-->
    <div style="display: flex;margin-top: 10px">
<!--      设计方量-->
      <div>
        <el-tag style="margin-bottom: 10px;margin-right: 5px" type="info">设计方量</el-tag>
        <el-input size="small" style="width: 200px;margin-right: 65px" clearable v-model="planfl" placeholder="请输入设计方量"></el-input>
      </div>
<!--      实际方量-->
      <div>
        <el-tag style="margin-bottom: 10px;margin-right: 5px" type="info">实际方量</el-tag>
        <el-input size="small" style="width: 200px;margin-right: 60px" clearable v-model="realfl" placeholder="请输入实际方量"></el-input>
      </div>
<!--      残余预留变形回填量-->
      <div>
        <el-tag style="margin-bottom: 10px;margin-right: 5px" type="info">残余预留变形回填量</el-tag>
        <el-input size="small" style="width: 220px" clearable v-model="ylht" placeholder="请输入残余预留变形回填量"></el-input>
      </div>
    </div>
<!--    备注-->
    <div style="margin-top: 10px">
      <el-tag style="margin-bottom: 10px;margin-right: 5px" type="info">备注</el-tag>
      <el-input size="small" style="width: 520px" clearable v-model="beizhu" placeholder="请输入备注"></el-input>
    </div>
    <div style="float: right;margin-top: 60px;margin-right: 60px">
      <el-button type="primary" @click="uplining">提交</el-button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {algorithm} from "@/utils/algorithm";
import {ElMessageBox} from "element-plus";
import { ElMessage } from 'element-plus'
export default {
  inject: ['reload'],
  name: "secondlin",
  data(){
    return{
      //flag
      opername: '',
      //opername
      opername1: '',
      opernames: [],
      mil: '',
      mils: [],
      //浇筑里程
      milxx: '',
      //浇筑长度
      milsum: '',
    //  围岩级别
      rock: '',
      rocks: [],
    //  浇筑时间
      jztime: '',
    //  设计方量
      planfl: '',
    //  实际方量
      realfl: '',
    //  回填量
      ylht: '',
      beizhu: '',
    //  标段
      bdst: ''
    }
  },
  created() {
    this.axios.post('/sink/getoper', (response) => {
      response.obj.forEach((item, i ) =>{
        item.forEach((item1, y) => {
          this.opernames.push(item1)
        })
      });
    },{
      bdst: this.GET_BDST
    })
  },
  computed: {
    ...mapGetters(['GET_BDST']),
    ...mapGetters(['GET_USER']),
    ...mapGetters(['GET_USERID']),
  },
  methods:{
    getflag(val){
      //获取隧道名称
      this.operid = val;
      this.axios.post('/sink/getopername', (response) =>{
        this.bdst = response.obj.bdst;
        this.opername1 = response.obj.operatingpoint;
      },{
        operid: val
      });
      //获取里程前缀
      this.axios.post('/other/getmil', (response) =>  {
        this.mils = response.obj
      },{
        id: this.opername
      })
    },
    //判断里程输入是否正确
    getnum(){
      if (this.milxx !== ''){
        let arr = this.milxx.split("-");
        if (arr.length === 1){
          //this.form.milsum = '0.0'
        }else {
          if (parseInt(arr[0]) > parseInt(arr[1])){
            this.milsum = algorithm(arr[0], -arr[1])
          }else {
            this.milsum = algorithm(arr[1], -arr[0])
          }
          this.milsum = Math.abs(this.milsum);
          let d1 = arr[0].split(".");
          let d2 = arr[1].split(".");
          if (d1[0].length < 4 || d2[0].length < 4){
            ElMessageBox.alert('里程填写有误', '错误', {
              confirmButtonText: 'OK',
              callback: (action) => {
              },
            })
          }
        }
      }else {
        this.milsum = ''
      }
    },
    //获取围岩级别
    getrocks(){
      this.axios.get('/other/getallrock', (response) =>  {
        this.rocks = response.obj
      })
    },
    uplining(){
      this.axios.post('/liningmain/savelin', (response) => {
        if (response.obj){
          this.reload();
          ElMessage({
            message: '提交成功',
            type: 'success',
          })
        }
      },{
        mile: this.mil + this.milxx,
        oppmile: this.milsum,
        stonelel: this.rock,
        jztime: this.jztime,
        planfl: this.planfl,
        realfl: this.realfl,
        ylht: this.ylht,
        beizhu: this.beizhu,
        opername: this.opername1,
        flag: this.opername,
        bdst: this.bdst,
        body: '1'
      })
    }
  }
}
</script>

<style scoped>

</style>